
import Vue from 'vue';

interface Props {
  text: string;
}

interface Data {
  isInitialTitleAnimation: boolean;
  isFinalTitleAnimation: boolean;
  timer: ReturnType<typeof setTimeout> | undefined;
  animationDuration: number;
}

interface Methods {
  clearTimer(): void;
  startAnimation(): void;
}

interface Computed {
  isAnimated: boolean;
}
export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInitialTitleAnimation: false,
      isFinalTitleAnimation: false,
      timer: undefined,
      animationDuration: 0,
    };
  },
  watch: {
    text: {
      async handler() {
        this.clearTimer();
        this.isInitialTitleAnimation = false;
        this.isFinalTitleAnimation = false;
        await this.$nextTick();
        if (this.isAnimated) {
          this.startAnimation();
        }
      },
      immediate: true,
    },
  },
  computed: {
    isAnimated() {
      return this.text.length > 50;
    },
  },
  methods: {
    startAnimation() {
      setTimeout(() => {
        const parentElement = this.$refs.titleContainer as HTMLElement;
        const animationDuration = (parentElement.scrollWidth / parentElement.clientWidth) * 1000;
        this.animationDuration = animationDuration * 10;
        this.isInitialTitleAnimation = true;

        this.timer = setTimeout(() => {
          this.animationDuration = (animationDuration + animationDuration / 2) * 10;
          this.isInitialTitleAnimation = false;
          this.isFinalTitleAnimation = true;
        }, animationDuration * 10);
      }, 2000);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = undefined;
    },
  },
  mounted() {
    this.startAnimation();
  },
});
