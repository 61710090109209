
import { formatDateForDay, getTime } from '@headlines/utils/BlockType23';
import Vue from 'vue';

import { Stream } from './types/blockType23';
import { BaseIcon } from '@core/exports';

interface Computed {
  howManyMinutesAgoStreamBegan: number;
  articleUrl: string;
}

interface Methods {
  formatDateForDay: typeof formatDateForDay;
  getTime: typeof getTime;
}

interface Props {
  isLive: boolean;
  isStreamToday: boolean;
  locale: string;
  remainingStreamsCount: number;
  streamToShow: Stream;
  streamsPageUrl: string;
}

const trackingHash = 'dalribbon-ulekanded';

export default Vue.extend<unknown, Methods, Computed, Props>({
  components: { BaseIcon },
  props: {
    isLive: {
      type: Boolean,
      required: true,
    },
    isStreamToday: {
      type: Boolean,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    streamToShow: {
      type: Object,
      required: true,
    },
    streamsPageUrl: {
      type: String,
      required: true,
    },
    remainingStreamsCount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    howManyMinutesAgoStreamBegan() {
      const date = new Date().getTime() - new Date(this.streamToShow.date.start).getTime();
      return Math.floor(date / 60000);
    },
    articleUrl() {
      if (!this.streamToShow.url) {
        return '';
      }

      const articleUrl = new URL(this.streamToShow.url);
      articleUrl.hash = trackingHash;
      return articleUrl.href;
    },
  },
  methods: {
    formatDateForDay,
    getTime,
  },
});
