export const Frontpage = () => import(/* webpackChunkName: "page/Frontpage" */ '../modules/frontpage/pages/frontpage.vue').then((m) => m.default || m);
export const Category = () => import(/* webpackChunkName: "page/Category" */ '../modules/category/pages/_id.vue').then((m) => m.default || m);
export const ArticleBase = () => import(/* webpackChunkName: "page/ArticleBase" */ '../modules/article/pages/article.vue').then((m) => m.default || m);
export const Article = () => import(/* webpackChunkName: "page/Article" */ '../modules/article/pages/_id.vue').then((m) => m.default || m);
export const ArticleComments = () => import(/* webpackChunkName: "page/ArticleComments" */ '../modules/article/pages/comments.vue').then((m) => m.default || m);
export const Tag = () => import(/* webpackChunkName: "page/Tag" */ '../modules/tags/pages/_id.vue').then((m) => m.default || m);
export const Authors = () => import(/* webpackChunkName: "page/Authors" */ '../modules/author/pages/authors.vue').then((m) => m.default || m);
export const Author = () => import(/* webpackChunkName: "page/Author" */ '../modules/author/pages/_id.vue').then((m) => m.default || m);
export const Issues = () => import(/* webpackChunkName: "page/Issues" */ '../modules/issue/pages/issues.vue').then((m) => m.default || m);
export const Issue = () => import(/* webpackChunkName: "page/Issue" */ '../modules/issue/pages/_id.vue').then((m) => m.default || m);
export const Search = () => import(/* webpackChunkName: "page/Search" */ '../modules/search/pages/search.vue').then((m) => m.default || m);
export const Custom = () => import(/* webpackChunkName: "page/Custom" */ '../modules/custom-solutions/pages/_slug.vue').then((m) => m.default || m);
export const CustomBase = () => import(/* webpackChunkName: "page/CustomBase" */ '../modules/custom-solutions/pages/custom.vue').then((m) => m.default || m);
export const CustomEmbeds = () =>
  import(/* webpackChunkName: "page/CustomEmbeds" */ '../modules/custom-solutions/pages/embeds.vue').then((m) => m.default || m);
export const Profile = () => import(/* webpackChunkName: "page/Profile" */ '../modules/customer/pages/profile.vue').then((m) => m.default || m);
export const Password = () => import(/* webpackChunkName: "page/Password" */ '../modules/customer/pages/resetPassword.vue').then((m) => m.default || m);
export const Latest = () => import(/* webpackChunkName: "page/Latest" */ '../modules/list/pages/latest.vue').then((m) => m.default || m);
export const Popular = () => import(/* webpackChunkName: "page/Popular" */ '../modules/list/pages/popular.vue').then((m) => m.default || m);
export const Bookmarks = () => import(/* webpackChunkName: "page/Bookmarks" */ '../modules/customer/pages/bookmarks.vue').then((m) => m.default || m);

export const routerComponents = {
  Frontpage,
  Category,
  ArticleBase,
  Article,
  ArticleComments,
  Tag,
  Authors,
  Author,
  Issues,
  Issue,
  Search,
  Custom,
  CustomBase,
  CustomEmbeds,
  Profile,
  Password,
  Latest,
  Popular,
  Bookmarks,
};
