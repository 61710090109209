import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import config from '../../config/media.config';

class PlayerAnalytics {
  public _mediaData!: AudioItem | VideoItem;
  public _player!: jwplayer.JWPlayer;
  private _initialPlay: boolean;
  private _eventMap;
  private _trackProgressEvents: Record<string, number | undefined>;

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem) {
    this._mediaData = mediaData;
    this._player = player;
    this._initialPlay = true;
    this._eventMap = config.analytics.eventsMap;
    this._trackProgressEvents = { ...config.analytics.trackProgressEvents };

    this.initAnalytics();
  }

  initAnalytics() {
    this.setEventsListeners();
  }

  getPercent() {
    if (this._player.getPosition()) {
      return Math.floor((this._player.getPosition() / this._player.getDuration()) * 100);
    }
    return 0;
  }

  protected eventParameters(parameters?: Record<string, string | number>) {
    const baseParameters = {
      media_id: this._mediaData.id,
      media_type: this._mediaData.type,
      media_title: this._mediaData.metadata.title || 'no-title',
      media_authors: this._mediaData.metadata.authors?.join() || '',
      media_category: this._mediaData.metadata.tags?.join(' > ') || '',
      media_credit: this._mediaData.metadata.credit || '',
      media_autoplay: this._mediaData.type === 'AUDIO' ? '0' : '1',
      media_muted: this._player.getMute() ? '1' : '0',
      media_mutestart: this._mediaData.type === 'AUDIO' ? '0' : '1',
      media_player_floation: this._player.getFloating() ? '1' : '0',
      media_position: Math.floor(this._player.getPosition()),
      media_grouping: this._mediaData?.metadata?.parentTitle || '',
    };

    return { ...baseParameters, ...parameters };
  }

  setEventsListeners() {
    for (const [jwEvent, eventName] of Object.entries(this._eventMap)) {
      this._player.on(jwEvent as keyof jwplayer.EventParams, () => {
        let eventType = eventName;

        if (jwEvent === 'play' && this._initialPlay) {
          eventType = 'media_start';
          this._initialPlay = false;
        }

        this.sendEvent(eventType);
      });
    }
    const secondsFired: number[] = [];

    this._player.on('time', (time) => {
      const percent = this.getPercent();
      const currentPosition = Math.floor(time.position);

      if (this._trackProgressEvents.media_25 === 1 && percent >= 25) {
        this.sendEvent('media_25');
        this._trackProgressEvents.media_25 = undefined;
      } else if (this._trackProgressEvents.media_50 === 1 && percent >= 50) {
        this.sendEvent('media_50');
        this._trackProgressEvents.media_50 = undefined;
      } else if (this._trackProgressEvents.media_75 === 1 && percent >= 75) {
        this.sendEvent('media_75');
        this._trackProgressEvents.media_75 = undefined;
      }

      if (currentPosition > 0 && (currentPosition === 3 || currentPosition % 30 === 0) && !secondsFired.includes(currentPosition)) {
        secondsFired.push(currentPosition);
        this.sendEvent('media_cons');
      }
    });
    // Send event when customer leaves the player page
    this._player.on('remove', () => this.sendEvent('media_seconds_played'));
    window.addEventListener('beforeunload', () => this.sendEvent('media_seconds_played'));
  }

  public sendEvent(eventName: string) {
    console.log('base class: ', eventName);
  }
}

export default PlayerAnalytics;
