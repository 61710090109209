import { Plugin, Context } from '@nuxt/types';
import { cXense } from '@analytics/trackers';
import { Data as ArticleData } from '@root/modules/article/types/article';
import { getConsentCookies } from '@root/modules/analytics/utils/cookieConsent';
import { localConsentCookieName } from '@root/modules/analytics/config/cookie-consent/cookieConsent.config';
import cXensePageType from '@root/modules/analytics/config/cxense/cXensePageType.config';
import events from '../config/cxense';
import interpolate from '@root/common/utils/interpolate';
import getMobileApplication from '../utils/getMobileApplication';
import { CxenseEvent } from '@root/modules/analytics/types/analytics';
import getEventByKey from '../utils/getEventByKey';
import getDelfiHashTracker from '../utils/getDelfiHashTracker';
import { getUid } from '../utils/getUid';
import { ChannelPages } from '@root/libs/init-channel/types';
import { allowedPianoCustomEvents } from '@root/modules/analytics/config/cxense/allowedPianoCustomEvents';
import TrackingInfoDecoder from '@root/common/utils/TrackingInfoDecoder';
import { QueryParameter } from '~/src/common/types/queryParameter';

type CustomParameters = Record<string, unknown>;

interface CXenseArticle extends ArticleData {
  issueArticle: boolean;
}

const cXensePlugin: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const analytics = app.$channelConfig('analytics');
  const { id, locale, application } = app.$channelConfig('settings');

  if (!analytics?.cxense?.siteId) {
    return;
  }

  const { specialFeatures } = application;
  const disableConsent = specialFeatures.disableConsentModal || false;

  const { consent: consentValue = null } = disableConsent
    ? {}
    : ((await getConsentCookies([localConsentCookieName], store.state.analytics.fetchSSRCookies)) as any);
  let consent;

  if (consentValue) {
    consent = {
      pv: true,
      ad: consentValue.ads,
      segment: consentValue.personalization,
      recs: consentValue.content,
    };
  }

  const cX = new cXense();
  await cX.init({ uid: analytics.cxense.siteId, consent });

  cX.push('cint', '224');
  cX.push('sync', 'adform');

  const isNativeApp = Boolean(app.$channelConfig('settings').application.type);
  const gemiusId = (routeName: string) => {
    const pageSettings = app.$channelConfig('page')[routeName as ChannelPages];

    return getUid({ defaultSettings: analytics.gemius, pageSettings: pageSettings?.analytics?.gemius, isNativeApp });
  };

  const setCustomerId = () => {
    const externalId = store.state.piano.profile.uidHash
      ? {
          id: store.state.piano.profile.uidHash,
          type: 'eks',
        }
      : null;
    cX.setExternalId(externalId);
  };

  const pageCustomParameters = () => {
    const routeName = app.router?.currentRoute.name;
    const hash = window.location.hash;
    const customParameters: CustomParameters = {};
    customParameters.channel = id;

    const hashParameter = getDelfiHashTracker(hash);
    if (hashParameter) {
      customParameters.delfi_link = hashParameter;
    }

    customParameters.platform = routeName?.includes('issue') ? 'Kaanest kaaneni' : 'Online vaade';
    customParameters.page_type = cXensePageType[routeName || 'other'] || cXensePageType['other'];

    const isLoggedIn = store.state.piano.isLoggedIn;
    customParameters.user_logged_in = isLoggedIn ? 'yes' : 'no';
    customParameters.has_user_package = store.state.piano.access.channelAccess ? 'yes' : 'no';
    customParameters.gem_id = gemiusId(String(routeName));

    const application = getMobileApplication(window.navigator.userAgent);
    if (application) {
      customParameters.browser_container = application;
    }

    return customParameters;
  };

  // Get cXense segments and share them to trackers
  window.addEventListener('load', async () => {
    const userId = await cX.getUserId();
    cX.push('getUserSegmentIds', {
      persistedQueryId: 'b04a959c2ea64c1ef725a6abb04ccecbbd9e6671',
      callback: async (segmentIds: string[]) => {
        store.commit('analytics/setClickEvent', {
          facebookPixel: {
            eventName: 'SYNC_SEGMENTS',
            eventData: { eventType: 'sync', data: { type: 'trackCustom', name: 'CxSegments', segmentIds } },
          },
          getSiteControl: {
            eventName: 'SYNC_SEGMENTS',
            eventData: { eventType: 'sync', data: { segmentIds, userId } },
          },
        });
      },
    });
  });

  const removeTrackingInfo = (url: URL) => {
    // Don't use router methods here, because they are triggering Nuxt Router hook
    // afterEach hook replaces referrer in analyticsTrackers.ts
    url.searchParams.delete(QueryParameter.TrackingParameter);
    url.hash = '';
    window.history.replaceState(null, '', url);
  };

  const cXenseArticlePageView = (activeArticle: CXenseArticle) => {
    const channelAccess = store.state.piano.access.channelAccess;

    setCustomerId();

    let customParameters = pageCustomParameters();
    const articleCustomParameters: CustomParameters = {
      is_paid_article: activeArticle.content.paywall.enabled,
      is_paid_article_and_have_paid: activeArticle.content.paywall.enabled && channelAccess,
    };

    // Add customer subscription info to custom parameters
    if (store.state.piano.subscriptionInfo) {
      articleCustomParameters.user_packages = store.state.piano.subscriptionInfo.rid;
      articleCustomParameters.product_start = store.state.piano.subscriptionInfo.startDate;
      articleCustomParameters.product_type = store.state.piano.subscriptionInfo.type;
    }

    if (store.state.piano.ipAccessToken) {
      articleCustomParameters.product_type = 'ip';
    }

    const url = new URL(ctx.route.fullPath, window.location.origin);
    const trackingParameter = url.searchParams.get(QueryParameter.TrackingParameter);

    if (trackingParameter) {
      const trackingInfoDecoder = new TrackingInfoDecoder();
      const trackingData = trackingInfoDecoder.decode(trackingParameter);
      if (trackingData) {
        articleCustomParameters.ref_blockindex = trackingData.blockIndex;
        articleCustomParameters.ref_blocktype = trackingData.blockType;
        articleCustomParameters.ref_pagetype = trackingData.pageType;
        articleCustomParameters.ref_sectionindex = trackingData.sectionIndex;
        articleCustomParameters.ref_siteName = trackingData.siteName;
        if (trackingData.blockId) {
          articleCustomParameters.ref_blockId = trackingData.blockId;
        }
      }
    }

    customParameters = { ...customParameters, ...articleCustomParameters };
    cX.pageView({}, customParameters);

    const routeHasDalHash = getDelfiHashTracker(ctx.route.hash);

    if (routeHasDalHash || trackingParameter) {
      removeTrackingInfo(url);
    }
  };

  const cXensePageView = (route: Context['route']) => {
    const isArticle = route.name === 'article' || route.name === 'issue-article';

    // Send page view of all pages except article
    if (isArticle) {
      return false;
    }

    const customParameters = pageCustomParameters();

    setCustomerId();
    cX.pageView({}, customParameters);

    const url = new URL(route.fullPath, window.location.origin);
    const routeHasDalHash = getDelfiHashTracker(route.hash);

    if (routeHasDalHash) {
      removeTrackingInfo(url);
    }
  };

  app.router!.afterEach((to, from) => {
    if (from.name) {
      cXensePageView(to);
    }
  });

  const startWatchingAccess = () => {
    store.watch(
      (state) => state.piano.access,
      async () => {
        if (store.state.article.activeArticle) {
          const { isIssue = false } = store.state.issue;
          const cXenseArticle = { ...{ issueArticle: isIssue }, ...store.state.article.activeArticle };
          cXenseArticlePageView(cXenseArticle);
        } else {
          cXensePageView(route);
        }
      }
    );
  };

  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        const { isIssue = false } = store.state.issue;
        const cXenseArticle = { ...{ issueArticle: isIssue }, ...store.state.article.activeArticle };
        cXenseArticlePageView(cXenseArticle);
      }
    }
  );

  if (store.state.piano.isScriptInited) {
    if (store.state.article.activeArticle) {
      const { isIssue = false } = store.state.issue;
      const cXenseArticle = { ...{ issueArticle: isIssue }, ...store.state.article.activeArticle };
      cXenseArticlePageView(cXenseArticle);
    } else {
      cXensePageView(route);
    }
    startWatchingAccess();
  } else {
    store.watch(
      (state) => state.piano.isScriptInited,
      async () => {
        if (store.state.article.activeArticle) {
          const { isIssue = false } = store.state.issue;
          const cXenseArticle = { ...{ issueArticle: isIssue }, ...store.state.article.activeArticle };
          cXenseArticlePageView(cXenseArticle);
        } else {
          cXensePageView(route);
        }
        startWatchingAccess();
      }
    );
  }

  // Watch analytics store eventName change
  store.watch(
    (state) => state.analytics.tracker.cXense.clickEventName,
    async (clickEventName) => {
      if (!clickEventName) {
        return;
      }
      const event = getEventByKey<CxenseEvent>(events, clickEventName, locale);

      if (event) {
        if (!store.state.analytics.tracker.cXense.clickEventData) {
          return false;
        }

        const { type, data } = store.state.analytics.tracker.cXense.clickEventData;
        const eventData = event.types[type];
        const { eventType, customParams, options } = eventData;
        const eventCustomParams = customParams ? JSON.parse(interpolate(customParams, { data, app })) : '';
        cX.sendEvent(eventType, eventCustomParams, options ? JSON.parse(options) : null);
      }
      store.commit('analytics/setClickEvent', { cXense: { eventName: null, eventData: null } });
    }
  );

  store.watch(
    (state) => state.analytics.arrayTracker.cXenseFunnel,
    async () => {
      if (!store.state.analytics.arrayTracker.cXenseFunnel.length) {
        return;
      }

      const firstEvent = store.state.analytics.arrayTracker.cXenseFunnel.shift();
      const event = getEventByKey<CxenseEvent>(events, firstEvent.eventName, locale, 'funnels');

      if (event) {
        const { type, data } = firstEvent.eventData;
        try {
          const eventData = event.types[type];
          const { eventType, customParams, options } = eventData;
          const eventCustomParams = customParams ? JSON.parse(interpolate(customParams, { data, app })) : '';
          cX.sendEvent(eventType, eventCustomParams, options ? JSON.parse(options) : null);
        } catch (e) {
          console.log(e);
        }
      }
    }
  );

  // Watch Piano custom tracking events
  store.subscribeAction((action) => {
    if (action.type !== 'piano/dispatchCustomEvent' || !allowedPianoCustomEvents.includes(action.payload.eventName)) {
      return;
    }

    const { params = '' }: { eventName: string; params?: string } = action.payload;
    let parsedParams: any = undefined;

    try {
      parsedParams = JSON.parse(params);
    } catch {
      console.log('Failed to parse dispatchCustomEvent params');
      return;
    }

    if (!parsedParams || !('linkTitle' in parsedParams)) {
      return;
    }

    store.commit('analytics/setClickEvent', {
      cXense: {
        eventName: 'PAYWALL_MODAL',
        eventData: { type: 'click', data: { linkTitle: parsedParams.linkTitle } },
      },
    });
  });
};

export default cXensePlugin;
