const key = {
  ENV_DEFAULT_KEY: process.env.PORTAL_ROOT_ENVIRONMENT || 'default_env',
  DEPLOY_PATH: 'portal_deploy_path',
};

const apis = {
  apisEndpoints: {
    default_env: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.ee/misc/common/pc/config.php',
      STREAMS_API_URL: 'https://portal-feeds.api.delfi.ee/portal-feeds-proxy/v1/stream-schedule',
      CONTENT_API_URL_V3: 'https://content.api.delfi.ee/content/v3',
      CONTENT_API_URL_V3_SERVER: 'https://content.api.delfi.ee/content/v3',
      MEDIA_API_URL_SERVER: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'https://media.api.delfi.ee/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.delfi.ee/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.delfi.ee/customer-data/v2',
      IP_ACCESS_API_URL: 'https://ip-access.api.delfi.ee/ip-access/v1',
      PIANO_WORKER_API_URL: 'https://piano-worker.api.delfi.ee/piano-worker/v1',
      CDN_PUBLIC_PATH: 'https://ee-production-portal-root.s3.delfi.ee',
    },
    ee_test: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.ee/misc/common/pc/config.php',
      STREAMS_API_URL: 'https://portal-feeds.api.delfi.ee/portal-feeds-proxy/v1/stream-schedule',
      CONTENT_API_URL_V3_SERVER: 'https://content.api.test.delfi.ee/content/v3',
      CONTENT_API_URL_V3: 'https://content.api.test.delfi.ee/content/v3',
      MEDIA_API_URL_SERVER: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'http://test-aws-ee.media-api/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.test.delfi.ee/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.test.delfi.ee/customer-data/v2',
      IP_ACCESS_API_URL: 'https://ip-access.api.test.delfi.ee/ip-access/v1',
      PIANO_WORKER_API_URL: 'https://piano-worker.api.test.delfi.ee/piano-worker/v1',
      CDN_PUBLIC_PATH: process.env.CDN_PUBLIC_PATH || 'https://ee-test-portal-root.s3.test.delfi.ee',
    },
    ee_production: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.ee/misc/common/pc/config.php',
      STREAMS_API_URL: 'https://portal-feeds.api.delfi.ee/portal-feeds-proxy/v1/stream-schedule',
      CONTENT_API_URL_V3: 'https://content.api.delfi.ee/content/v3',
      CONTENT_API_URL_V3_SERVER: 'http://ee-production-content-api-v3-auto-deploy.content-api-v3/content/v3',
      MEDIA_API_URL_SERVER: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'http://ee-production-media-api-auto-deploy.media-api/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.delfi.ee/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.delfi.ee/customer-data/v2',
      IP_ACCESS_API_URL: 'https://ip-access.api.delfi.ee/ip-access/v1',
      PIANO_WORKER_API_URL: 'https://piano-worker.api.delfi.ee/piano-worker/v1',
      CDN_PUBLIC_PATH: process.env.CDN_PUBLIC_PATH || 'https://ee-production-portal-root.s3.delfi.ee',
    },
    ee_review: {
      ARTICLE_EXTRA_CONTENT_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      ARTICLE_EXTRA_CONTENT_API_URL: 'https://www.delfi.ee/misc/common/pc/article_extra.php',
      CONFIG_API_URL_SERVER: 'https://www.delfi.ee/misc/common/pc/config.php',
      CONFIG_API_URL: 'https://www.delfi.ee/misc/common/pc/config.php',
      STREAMS_API_URL: 'https://portal-feeds.api.delfi.ee/portal-feeds-proxy/v1/stream-schedule',
      CONTENT_API_URL_V3: 'https://content.api.delfi.ee/content/v3',
      CONTENT_API_URL_V3_SERVER: 'https://content.api.delfi.ee/content/v3',
      MEDIA_API_URL_SERVER: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_URL: 'https://images.delfi.ee/media-api-image-cropper/v1',
      MEDIA_API_DATA_URL_SERVER: 'http://ee-production-media-api-auto-deploy.media-api/media/v1',
      MEDIA_API_DATA_URL: 'https://media.api.delfi.ee/media/v1',
      CUSTOMER_DATA_API_URL: 'https://customer.api.delfi.ee/customer-data/v2',
      IP_ACCESS_API_URL: 'https://ip-access.api.delfi.ee/ip-access/v1',
      PIANO_WORKER_API_URL: 'https://piano-worker.api.delfi.ee/piano-worker/v1',
      CDN_PUBLIC_PATH: process.env.CDN_PUBLIC_PATH || `https://ee-review-portal-root.s3.review.delfi.ee/${key.DEPLOY_PATH}`,
    },
  },
};

const channelEnv = key.ENV_DEFAULT_KEY as keyof typeof apis.apisEndpoints;
const DEFAULT_CLIENT_CONFIG = apis.apisEndpoints[channelEnv];

export default DEFAULT_CLIENT_CONFIG;
