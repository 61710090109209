export default {
  QUESTIONNAIRE_SUBMIT: {
    types: {
      submit: {
        eventType: 'questionnaire_submit',
        customParams: '{"GscWidgetId": "${data.widgetId}", "GscWidgetAnswer": "${data.rating}"}',
        options: '{"origin":"eks-delfi", "persistedQueryId": "2f43d4536ca56638c0eebb7a48b0b99e6f5f0d68"}',
      },
    },
  },
  ONSESIGNAL_NOTIFICATION: {
    types: {
      click: {
        eventType: 'pushnotif_opened',
        customParams:
          '{"OneSignalUserId": "${data.oneSignalUserId}", "ArticleId": "${app.store.state.article.activeArticle.id}", "Channel: "${data.channelId}"}',
        options: '{"origin":"eks-delfi", "persistedQueryId": "216d6eeb6eb9d470a55513cbf46429a600d2b6b4"}',
      },
    },
  },
  PAYWALL_MODAL: {
    types: {
      click: {
        eventType: 'link_click',
        customParams:
          '{"BlockName": "Article Paywall", "BlockType": "Paywall", "ArticleId": "${app.store.state.article.activeArticle.id}", "LinkTitle": "${data.linkTitle}", "SiteName": "${app.$channelConfig("settings").name}", "PageType": "Article"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      view: {
        eventType: 'purchase_completed',
        customParams:
          '{"BlockName": "Article Paywall", "PurchaseType": "Subscription", "ArticleId": "${app.store.state.article.activeArticle.id}", "PageType": "Article", "SiteName": "${app.$channelConfig("settings").name}"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  ALERT: {
    types: {
      click: {
        eventType: 'block_click',
        customParams: '{"BlockType": "alert", "BlockName": "${data.title}", "LinkTitle": "${data.linkTitle}"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      view: {
        eventType: 'block_show',
        customParams: '{"BlockType": "alert", "BlockName": "${data.title}"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      hide: {
        eventType: 'block_hide',
        customParams: '{"BlockType": "alert", "BlockName": "${data.title}"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  ABANDONED_CART_CHECKOUT: {
    types: {
      alert: {
        eventType: 'block_show',
        customParams: '{"BlockType": "Abandoned Cart Checkout", "BlockName": "Checkout from alert"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
      link: {
        eventType: 'block_show',
        customParams: '{"BlockType": "Abandoned Cart Checkout", "BlockName": "Checkout from link"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  ARTICLE_REACTIONS: {
    types: {
      reaction: {
        eventType: 'reaction_click',
        customParams:
          '{"BlockName": "Article Reaction","BlockType": "reaction","Emotion": "${data.emotion}","ArticleId": "${app.store.state.article.activeArticle.id}","IsPaywalledArt": "${data.isPaywalledArt}","IsDesktopView": "${data.isDesktopView}" }',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
  PIANO_LOAD_FAILURE: {
    types: {
      view: {
        eventType: 'piano_initialization_failure',
        customParams:
          '{"BlockName": "Piano Initialization Failure","BlockType": "Piano Initialization Failure","ArticleId": "${data.activeArticleId}","FailureCount": "${data.scriptFailedCount}"}',
        options: '{"origin":"eks-blocks", "persistedQueryId": "d7d9d91055626577dd7b77e5ad24c8ae33726ed9"}',
      },
    },
  },
};
